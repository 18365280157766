@use 'sass:map';

// This file is lovingly co-opted from CSS-tricks, modified to use MUI values
// https://css-tricks.com/snippets/sass/mixin-manage-breakpoints/
$base: (
  'xs': 0,
  'sm': 600px,
  'md': 900px,
  'lg': 1280px,
  'xl': 1600px,
  'xxl': 1920px,
);

$aliases: (
  'mobile': map.get($base, 'sm'),
  'tablet': map.get($base, 'md'),
  'desktop': map.get($base, 'lg'),
  'large-desktop': map.get($base, 'xl'),
  'extra-large-desktop': map.get($base, 'xxl'),
);

$breakpoints: map.merge($base, $aliases) !default;

// Could these be de-duplicated? Probably.
// Is it worth digging through the SASS documentation?
// No. Very little is.
@mixin up($breakpoint) {
  @if map.has-key($breakpoints, $breakpoint) {
    @media (min-width: map.get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`."
    + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`."
    + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
