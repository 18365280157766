@use 'typography';
@use 'colors';

// content-box is not a sane default
* {
  box-sizing: border-box;
}

// Make this flex friendly so full-width/height elements are more accessible
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: colors.$white;
}

body {
  display: flex;
  flex-direction: column;

  // Typography defaults
  @include typography.body1;
  color: colors.$charcoal;
}

#__next {
  flex-grow: 1;
  overflow: auto;
  background-color: colors.$white;
}

// Reset the weird browser defaults
a {
  text-decoration: none;
}
