@use 'media';
@use 'colors';

@mixin base {
  font-family: 'Noto Sans', 'Roboto', 'sans-serif';
}

@mixin metfont {
  font-family: 'MetLifeCircularWeb', 'Roboto', 'sans-serif';
}

@mixin body1 {
  @include base;

  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.18px;

  @include media.down('tablet') {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.16px;
  }

  @include media.down('mobile') {
    font-size: 16px;
  }
}

@mixin body2 {
  @include base;

  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.16px;

  @include media.down('tablet') {
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 26px;
  }

  @include media.down('mobile') {
    font-size: 14px;
  }
}

@mixin body3 {
  @include base;

  font-size: 14px;
  line-height: 26px;
  letter-spacing: -0.1px;
}

@mixin h1 {
  @include metfont;
  font-weight: 600;
  font-size: 44px;
  letter-spacing: -0.99px;
  line-height: 50px;

  @include media.down('tablet') {
    font-size: 38px;
    letter-spacing: -0.55px;
    line-height: 44px;
  }

  @include media.down('mobile') {
    font-size: 24px;
    letter-spacing: -0.54px;
    line-height: 30px;
  }
}

@mixin h2 {
  @include metfont;
  font-weight: 600;
  font-size: 32px;
  letter-spacing: -0.46px;
  line-height: 40px;

  @include media.down('tablet') {
    font-size: 28px;
    letter-spacing: -0.5px;
    line-height: 38px;
  }
}

@mixin h3 {
  @include metfont;
  font-weight: 600;
  font-size: 28px;
  letter-spacing: -0.46px;
  line-height: 38px;

  @include media.down('tablet') {
    font-size: 24px;
    letter-spacing: -0.5px;
    line-height: 34px;
  }
}

@mixin h4 {
  @include metfont;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: -0.46px;
  line-height: 32px;
}

@mixin subheader1 {
  @include metfont;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 30px;

  @include media.down('tablet') {
    letter-spacing: 0;
    line-height: 28px;
  }
}

@mixin subheader2 {
  @include subheader1;
  font-weight: 700;
  @include media.down('mobile') {
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
  }
}

@mixin quote {
  @include metfont;
  font-size: 20px;
  line-height: 30px;

  @include media.down('tablet') {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
  }
}

@mixin calltoaction1 {
  @include metfont;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.57px;
  line-height: 16px;
}

@mixin calltoaction2 {
  @include metfont;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
}
